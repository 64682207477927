import { ISideMenuItem } from "components/admin";
import { useAppContext } from "contexts";
import { ALL_ADMIN_ROLES } from "helpers/constants";
import { ITopMenuItem } from "layout/HeaderComponent/dto";
import { useRouter } from "next/router";
import React, { useMemo } from "react";

type AdminSection =
  | "Main"
  | "Points shop"
  | "Roles management"
  | "Loot boxes"
  | "Slot reviews"
  | "Games";

const sideMenuItems: ISideMenuItem[] = [
  {
    href: "/admin/users",
    name: "Users",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.USERS_ADMIN],
  },
  {
    href: "/admin/black-list",
    name: "BlackList",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.USERS_ADMIN],
  },
  {
    href: "/admin/users-multi",
    name: "MultiAccounts",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.USERS_ADMIN],
  },
  {
    href: "/admin/notifications",
    name: "Notifications",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/raffles",
    name: "Raffles",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.RAFFLE_ADMIN],
  },
  {
    href: "/admin",
    match: ["/admin", /^[\/]admin[\/]leaderboard(?:[\/].*)?/],
    name: "Leaderboard",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.LEADERBOARD_ADMIN],
  },
  {
    href: "/admin/pachinko",
    name: "Pachinko",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.PACHINKO_ADMIN],
  },
  {
    href: "/admin/stream-guests",
    name: "Stream guests",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.PICK_A_GUEST_ADMIN],
  },
  {
    href: "/admin/site-rewards",
    name: "Site Rewards",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.SITE_REWARD_ADMIN],
  },
  ...(process.env.NEXT_PUBLIC_SHOW_CONTACT_US === "true"
    ? [
        {
          href: "/admin/contact-requests",
          name: "Contact Requests",
          section: "Main",
          permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.USERS_ADMIN],
        },
      ]
    : []),
  ...(process.env.NEXT_PUBLIC_SHOW_ADMIN_CALENDAR === "true"
    ? [
        {
          href: "/admin/calendar",
          name: "Calendar",
          section: "Main",
          permissions: [ALL_ADMIN_ROLES.CALENDAR_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
        },
      ]
    : []),
  {
    href: "/admin/points-shop",
    name: "Point shop",
    section: "Points shop",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.POINTS_SHOP_ADMIN],
  },
  {
    href: "/admin/points-shop/transactions",
    name: "Transactions",
    section: "Points shop",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.POINTS_SHOP_ADMIN],
  },
  {
    href: "/admin/permissions",
    name: "Admin List",
    section: "Roles management",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/permissions/roles",
    name: "Roles Overview",
    section: "Roles management",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/gallery",
    name: "Gallery",
    section: "Main",
    permissions: [
      ALL_ADMIN_ROLES.RAFFLE_ADMIN,
      ALL_ADMIN_ROLES.SUPER_ADMIN,
      ALL_ADMIN_ROLES.SITE_REWARD_ADMIN,
      ALL_ADMIN_ROLES.CASE_ADMIN,
    ],
  },
  {
    href: "/admin/lootbox",
    name: "Loot boxes",
    section: "Loot boxes management",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.CASE_ADMIN],
  },
  {
    href: "/admin/lootbox-item",
    name: "Loot boxes items",
    section: "Loot boxes management",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.CASE_ADMIN],
  },
  {
    href: "/admin/lootbox-transaction",
    name: "Loot boxes transactions",
    section: "Loot boxes management",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.CASE_ADMIN],
  },
  {
    href: "/admin/slot",
    name: "Slots",
    section: "Slot reviews",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/slot-provider",
    name: "Slot providers",
    section: "Slot reviews",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/games/mines",
    name: "Mines",
    section: "Games",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/games/coinflip",
    name: "Coinflip",
    section: "Games",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/games/roulette",
    name: "Roulette",
    section: "Games",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/games/rates",
    name: "Rates",
    section: "Games",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/treasury/box",
    name: "Boxes",
    section: "Treasure",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.CASE_ADMIN],
  },
  {
    href: "/admin/treasury/daily",
    name: "Daily",
    section: "Treasure",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.CASE_ADMIN],
  },
];

const adminItems: ITopMenuItem[] = [
  { name: "Main" as AdminSection },
  { name: "Points shop" as AdminSection },
  { name: "Roles management" as AdminSection },
  { name: "Loot boxes" as AdminSection },
  { name: "Slot reviews" as AdminSection },
  { name: "Games" as AdminSection },
];
const isActive = (pathname: string, match: string | RegExp | Array<string | RegExp>): boolean => {
  let result = false;
  if (match === "#") {
    result = false;
  } else if (match instanceof RegExp) {
    result = match.test(pathname);
  } else if (Array.isArray(match)) {
    result = match.some((match) => isActive(pathname, match));
  } else if (typeof match === "string") {
    result = pathname === match;
  }
  return result;
};

export default function useAdminItems() {
  const router = useRouter();
  const { profile } = useAppContext();
  const roles = useMemo(() => {
    return profile?.roles ? profile.roles?.map((role) => role.name) : [];
  }, [profile?.roles]);

  const validItems: ISideMenuItem[] = React.useMemo(() => {
    return sideMenuItems
      .filter((item) => item.permissions.some((permission) => roles?.includes(permission)))
      .map(({ href, match = [], ...item }) => {
        return {
          isActive: isActive(router.pathname, [href, ...match]),
          href,
          ...item,
        };
      });
  }, [router.pathname, roles]);

  const adminItems: ITopMenuItem[] = React.useMemo(() => {
    const sections = Array.from(new Set(validItems.map((item) => item.section)));

    return sections.map((section) => {
      return {
        items: validItems
          .filter((item) => item.section === section)
          .map(
            (item) =>
              ({
                name: item.name,
                href: item.href,
              } as ITopMenuItem)
          ),
        name: section,
      };
    });
  }, [validItems]);

  return { adminItems };
}
