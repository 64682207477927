import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useAppContext } from "contexts";
import { CoinBox, CoinBoxHistoryItem } from "types/treasury";

export function useCoinBoxHistory(
  params: { take: number; skip: number } = { take: 10, skip: 0 },
  options?: UseQueryOptions<{ items: CoinBoxHistoryItem[]; totalCount: number }, Error>
) {
  const { apiService } = useAppContext();
  return useQuery({
    queryKey: ["coin-boxes-history", params],
    queryFn: async () => {
      const response = await apiService.request(
        `/coin-box/history?take=${params.take}&skip=${params.skip}`,
        {
          method: "GET",
        }
      );
      const data = await response?.json();
      return data;
    },
    initialData: { items: [], totalCount: 0 },
    ...options,
  });
}
