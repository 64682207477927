import React, { useState } from "react";
import styled, { css } from "styled-components";
import { IStyles, $styles, Button, ButtonBlue } from "components";
import { DualRingInner, DualRingLoader, Flex } from "components/common";
import Image from "next/image";
import { Description, SubHeader } from "../ItemsTable/ItemsTable";
import { formatNumber } from "components/pachinko/Prizes";
import { triggerNotification } from "components/TheComponents/Notification";
import { useAppContext } from "contexts";
import SuccessfulPurchaseLayout from "./SuccessfulPurchaseModal";
import FailedPurchaseLayout from "./FailedPurchaseLayout";
import { ItemProps } from "../types";
import { useDisclosure } from "@chakra-ui/react";
import ChakraModal from "components/TheComponents/Modal";
import { ExceptionHandlingType } from "types/apiServiceTypes";
import { ApiError } from "next/dist/server/api-utils";

const Info = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  width: 100%;

  @media (min-width: 1024px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const InfoLabel = styled(Flex)`
  flex: 0, 1;
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: rgba(200, 215, 245, 0.03);
  color: #d5e0f5;
  font-family: "Golos Text";
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;

  @media (min-width: 1024px) {
    width: calc(50% - 4px);
  }
`;

const PurchaseModalFooter = styled(Flex)`
  width: 100%;
  flex-direction: column;
  border-radius: 5px 5px 0px 0px;
  background: rgba(29, 34, 52, 0.5);
  backdrop-filter: blur(55px);
  width: calc(100% + 48px);
  transform: translate(-24px, 24px);
  padding: 24px;
  gap: 24px;

  @media (min-width: 1920px) {
    padding: 34px 48px;
    gap: 30px;
  }
`;

const Price = styled(Flex)`
  align-items: center;
  width: 100%;
  gap: 4px;
  color: #d5e0f5;
  text-align: center;
  font-family: "Golos Text";
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
`;

interface PurchaseModalProps {
  onClose: () => void;
  isDiscordLinked?: boolean;
  selectedItem: ItemProps;
}

const PurchaseLayout: React.FC<PurchaseModalProps> = ({
  onClose,
  selectedItem,
}): React.ReactElement => {
  const { apiService, refetchProfile } = useAppContext();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { isOpen: isSuccessOpen, onOpen: onOpenSuccess, onClose: onCloseSuccess } = useDisclosure();
  const { isOpen: isFailedOpen, onOpen: onOpenFailed, onClose: onCloseFailed } = useDisclosure();
  const [errorMessage, setErrorMessage] = useState("");

  const buyItem = async (event: React.MouseEvent) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      await apiService.post(
        "/point-shop/purchases",
        {
          body: JSON.stringify({
            pointsShopItemId: selectedItem.id,
          }),
        },
        ExceptionHandlingType.MANUAL
      );

      onOpenSuccess();
    } catch (error: any) {
      if (error.message) {
        setErrorMessage(error.message);
        onOpenFailed();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onSuccess = async () => {
    onCloseSuccess();
    onClose();

    refetchProfile();
  };

  return (
    <Flex column gap={16}>
      <Flex justify="space-between" gap={2} width="100%" align="center">
        <Image src={selectedItem.imageUrl} height={150} width={150} alt="image" />

        <SubHeader style={{ fontSize: 16, color: "#A3B6CA" }}>{selectedItem.title}</SubHeader>
      </Flex>

      <Description
        style={{
          flexDirection: "column",
          width: "100%",
          borderRadius: "8px",
          border: "1px solid var(--daylight-daylight-10, rgba(200, 215, 245, 0.10))",
          padding: "16px",
          textAlign: "left",
        }}
        title={selectedItem.description}
      >
        <p style={{ color: "#D5E0F5", margin: "0 0 12px 0" }}>Description</p>
        {selectedItem.description}
      </Description>

      <Info>
        <InfoLabel>
          1 item <span style={{ color: "rgba(200, 215, 245, 0.50)" }}>per user</span>
        </InfoLabel>
        <InfoLabel>
          <Image src="/images/icons/cart_1.svg" height={24} width={24} alt="image" />
          <span style={{ color: "rgba(200, 215, 245, 0.50)" }}>sold</span>{" "}
          {selectedItem.stockQuantity - selectedItem.quantity} times
        </InfoLabel>
        <InfoLabel style={{ width: "100%" }}>
          only <span style={{ color: "#FF3355" }}> {selectedItem.quantity} left</span>
        </InfoLabel>
      </Info>

      <PurchaseModalFooter>
        <Price>
          <Image src="/images/PointShopComponent/coin_2.svg" height={24} width={24} alt="image" />

          {formatNumber(selectedItem.price)}
        </Price>

        <Button
          isLoading={isLoading}
          styles={css`
            height: 50px;
            width: 100%;
          `}
          onClick={buyItem}
        >
          <ButtonBlue style={{ whiteSpace: "nowrap" }}>
            {isLoading ? (
              <DualRingLoader smallRing>
                <DualRingInner smallRing />
              </DualRingLoader>
            ) : (
              "CONFIRM"
            )}
          </ButtonBlue>
        </Button>
      </PurchaseModalFooter>

      {/* Success case */}
      <ChakraModal
        isOpen={isSuccessOpen}
        onClose={onSuccess}
        content={<SuccessfulPurchaseLayout selectedItem={selectedItem} onClose={onSuccess} />}
      />

      {/* Fail case */}
      <ChakraModal
        isOpen={isFailedOpen}
        onClose={onCloseFailed}
        content={<FailedPurchaseLayout errorMessage={errorMessage} onClose={onCloseFailed} />}
      />
    </Flex>
  );
};

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;

  ${$styles}
`;

export default PurchaseLayout;
