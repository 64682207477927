import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useAppContext } from "contexts";
import { DateTime } from "luxon";
import { ApiError } from "services/refreshService";
import { ExceptionHandlingType } from "types/apiServiceTypes";
import { CoinBoxPublic } from "types/treasury";

export type CoinBoxPublicStateType = CoinBoxPublic & { isReady: boolean };
export function useCoinBoxes(options?: UseQueryOptions<CoinBoxPublicStateType[], Error>) {
  const { apiService } = useAppContext();
  return useQuery({
    queryKey: ["coin-boxes"],
    queryFn: async () => {
      try {
        const response = await apiService.request(
          `/coin-box/user-stats`,
          {
            method: "GET",
          },
          ExceptionHandlingType.MANUAL
        );
        const data = await response?.json();
        return data;
      } catch (error: unknown) {
        if (error instanceof ApiError && error.code === 401) {
          const response = await apiService.request(
            `/coin-box`,
            {
              method: "GET",
            },
            ExceptionHandlingType.AUTOMATIC
          );
          const claimer = await response?.json();
          return claimer;
        }
      }
    },
    select(data) {
      return data?.map((item) => {
        return {
          ...item,
          isReady: !!item.coinBoxLatestHistory?.[0]
            ? DateTime.fromISO(item.coinBoxLatestHistory?.[0].openedAt)
                .plus({ seconds: item.cooldownSec })
                .diffNow("seconds").seconds < 0
            : true,
        };
      });
    },
    ...options,
  });
}
