import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { socket } from "socket";
import { DualRingInner, DualRingLoader, Flex, NoEntitiesText } from "components/common";
import { ExtendedFlexProps } from "../../pachinko/types";
import { ItemProps, StatMessageProps, DescriptionProps } from "../types";
import { IAppContext, withApp } from "contexts";
import { TableHeader } from "../TableHeader";
import AdminDeleteModal from "../Modals/AdminDeleteModal";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  PointerSensor,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import AdminRestockModal from "../Modals/AdminRestockModal";
import ChakraModal from "components/TheComponents/Modal";
import { CircularProgress, Progress, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import PurchaseLayout from "../Modals/PurchaseLayout";
import DiscordLayout from "../Modals/DiscordLayout";
import AdminActionLayout from "../Modals/AdminActionLayout";
import { SocketEvent } from "helpers/constants";
import apiService from "services/apiService";
import { ExceptionHandlingType } from "types/apiServiceTypes";
import Carousel from "./Carousel";
import { scrollToElement } from "helpers/scrollToElement";

interface IProps extends IAppContext {}

// const pageSizes = [15, 30, 60, 90];

const ExtendedFlex = styled(Flex)<ExtendedFlexProps>`
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 1180px) {
    flex-direction: column;
    width: 90%;
  }
  width: 90%;
  height: 100%;
  ${({ stat }) =>
    stat
      ? css`
          width: 70%;
          @media (max-width: 1180px) {
            width: 90%;
          }
        `
      : null}
`;

const ContainerFlex = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin: 32px auto 0;
  max-width: 100%;

  @media (min-width: 1400px) {
    max-width: calc(100vw - 576px);
    padding: 0;
  }

  @media (min-width: 1920px) {
    width: 1280px;
  }
`;

export const TitleText = styled.h1`
  color: rgba(210, 234, 255, 1);
  font-family: var(--font-family-golos);
  font-size: 18px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-align: left;

  @media (max-width: 1180px) {
    text-align: center;
    width: 100%;
    font-size: 20px;
  }
  text-shadow: 0px 2px 4px rgba(11, 14, 35, 1), 0px 0px 6px rgba(58, 113, 254, 0.46),
    0px 0px 24px rgba(40, 113, 255, 0.5);
`;

const ItemsContainer = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-gap: 16px;

  @media (min-width: 510px) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1024px) {
    grid-gap: 32px 16px;
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1400px) and (max-width: 1679px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1920px) {
    grid-template-columns: repeat(5, 1fr);
  }

  .swiper {
    grid-row: 3 / 3;
    grid-column: 1 / 3;

    @media (min-width: 768px) {
      grid-column: 1 / 4;
    }

    @media (min-width: 1024px) {
      grid-column: 1 / 5;
    }

    @media (min-width: 1400px) and (max-width: 1679px) {
      grid-column: 1 / 4;
    }

    @media (min-width: 1920px) {
      grid-column: 1 / 6;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 50px 24px 24px 24px;
  line-height: 150%;
  gap: 8px;
  height: 405px;
  border-radius: 16px;
  border: 1px solid rgba(29, 34, 52, 0.5);
  background: #151c2c;
`;

const CardsContainerFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
`;

export const StatMessage = styled.div<StatMessageProps>`
  letter-spacing: 0.02em;
  line-height: 150%;
  font-size: 18px;
  height: ${({ solo }) => (solo ? "70%" : "35%")};
  width: 100%;
  font-weight: 400;
  color: #d2eaff;
  @media (max-width: 1180px) {
    font-size: 16px;
  }
`;

export const SubHeader = styled.h1<DescriptionProps>`
  position: relative;
  color: #d5e0f5;
  font-family: "Golos Text";
  font-size: 16px;
  font-weight: 600;

  ${({ showTooltip }) =>
    showTooltip
      ? css`
          /* Tooltip styles */
          &::after {
            content: attr(title);
            position: absolute;
            bottom: 100%;
            border-radius: 8px;
            left: 50%;
            transform: translate(-50%, -15px);
            background-color: rgba(0, 0, 0, 0.7);
            color: #fff;
            padding: 10px; /* Increase the padding to make the tooltip higher */
            width: 100%; /* Set the width of the tooltip */
            font-size: 12px;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0.3s;
          }

          &:hover::after {
            opacity: 1;
            visibility: visible;
          }
        `
      : null}
`;

export const Description = styled.h1`
  position: relative; /* Add position: relative to enable positioning of the tooltip */
  text-align: center;
  margin: 0;
  color: #99abbd;
  padding: 5px;
  font-weight: 300;
  font-size: 14px;
  @media (max-width: 1280px) {
    font-size: 12px;
  }
`;

export const ExtendedSubHeader = styled(SubHeader)`
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-align: left;
  @media (min-width: 600px) and (max-width: 750px) {
    font-size: 14px;
  }
`;

interface ItemsTableProps {
  refetchDashboard: () => void;
  isAdminPage: boolean | undefined;
  scrollToTarget: () => void;
}
const step = 3;

const ItemsTable: React.FC<IProps & ItemsTableProps> = ({
  accessFetch,
  refetchDashboard,
  loginModalProps,
  profile,
  isAdminPage,
  scrollToTarget,
}) => {
  const isMobile = useMediaQuery("(max-width: 767px)")[0];

  const { onOpenLogin } = loginModalProps;
  const {
    isOpen: isPurchaseOpen,
    onOpen: onOpenPurchase,
    onClose: onClosePurchase,
  } = useDisclosure();
  const { isOpen: isRestockOpen, onOpen: onOpenRestock, onClose: onCloseRestock } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
  const { isOpen: isCreateOpen, onOpen: onOpenCreate, onClose: onCloseCreate } = useDisclosure();

  const [items, setItems] = React.useState<ItemProps[]>([]);
  const [skip, setSkip] = React.useState<number>(0);
  const [selectedItem, setSelectedItem] = React.useState<any>(null);
  const [favoriteItems, setFavoriteItems] = React.useState<any>(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [sorter, setSorter] = React.useState<string>("order:asc");
  const [take, setTake] = React.useState<number>(25);
  const [total, setTotal] = React.useState<number>(0);
  const [value, setValue] = React.useState<string>("");

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const onLoadMore = () => {
    const stepC = step * 4;
    if (take + stepC >= total) {
      setTake(total);
    } else {
      setTake((t) => t + stepC);
    }
  };

  const fetchData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const searchParam = value ? `search=${value}` : "";
      const sortParam = sorter !== "order:asc" ? `sort=${sorter}:desc` : `sort=${sorter}`;
      const skipTakeParams = `skip=${skip}&take=${take}`;

      const [response, favoriteResponse] = await Promise.all([
        accessFetch(`
          /point-shop/items?${searchParam}${searchParam && sortParam ? "&" : ""}${sortParam}${
          sortParam && skipTakeParams ? "&" : ""
        }${skipTakeParams}
      `),
        apiService.request(
          "/point-shop/get-user-favorite",
          undefined,
          ExceptionHandlingType.AUTOMATIC
        ),
      ]);

      const [data, favoriteData] = await Promise.all([response?.json(), favoriteResponse?.json()]);

      setItems(data?.items);
      setTotal(data?.totalCount);
      setFavoriteItems(favoriteData?.map((item: any) => item.pointsShopItemId) || []);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  }, [value, sorter, skip, take, accessFetch]);

  React.useEffect(() => {
    fetchData(); // Initial data fetch
  }, [fetchData]);

  const changeOrder = React.useCallback(async (updatedItems: ItemProps[], skip: number) => {
    try {
      const urlOrigin = process.env.NEXT_PUBLIC_APP_BE_ORIGIN || "http://localhost:4000";
      await fetch(`${urlOrigin}/point-shop/items/change-order`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          items: updatedItems.map((item: ItemProps, index: number) => ({
            id: item.id,
            order: index + 1 + skip,
          })),
        }),
        credentials: "include",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  const onSelectItem = (item: ItemProps) => {
    if (profile) {
      if (!isAdminPage) {
        setSelectedItem(item);
        onOpenPurchase();
      } else {
        setSelectedItem(item);
        onOpenEdit();
      }
    } else {
      onOpenLogin();
    }
  };

  const onClose = () => {
    if (!isAdminPage) {
      onClosePurchase();
      setSelectedItem(null);
    } else {
      onCloseEdit();
      onCloseCreate();
      onCloseRestock();
      onCloseDelete();
      setSelectedItem(null);
    }
  };

  React.useEffect(() => {
    socket.on(SocketEvent.PointsShopItemCreated, (data) => {
      const isPageFull = items.length >= take;
      setTotal((prev) => prev + 1);
      if (!isPageFull) {
        setItems((prevItems) => [...prevItems, data]);
      }
    });

    socket.on(SocketEvent.PointsShopItemUpdated, (data) => {
      const isItemOnPage = items.some((el) => el.id === data.id);
      if (isItemOnPage) {
        setItems((prevItems) => {
          return prevItems.map((item) => {
            if (item.id === data.id) {
              return data;
            } else {
              return item;
            }
          });
        });
      }
    });

    socket.on(SocketEvent.PointsShopItemPurchased, (data) => {
      const isItemOnPage = items.some((el) => el.id === data.id);
      if (isItemOnPage) {
        setItems((prevItems) => {
          return prevItems.map((item) => {
            if (item.id === data.id) {
              return { ...item, quantity: Math.max(item.quantity - 1, 0) };
            } else {
              return item;
            }
          });
        });
      }
    });

    socket.on(SocketEvent.PointsShopItemDeleted, (data) => {
      const isPageFull = items.length >= take;
      if (items.length === 1 && total > 1) {
        setSkip(0);
      }
      setTotal((prev) => prev - 1);
      if (!isPageFull) {
        setItems((prevItems) => {
          return prevItems.filter((item) => item.id !== data.id);
        });
      }
    });

    socket.on(SocketEvent.PointsShopItemsRestocked, () => {
      setItems((prev) =>
        prev.map((i) => ({
          ...i,
          quantity: i.stockQuantity,
        }))
      );
    });

    return () => {
      socket.off(SocketEvent.PointsShopItemCreated);
      socket.off(SocketEvent.PointsShopItemPurchased);
      socket.off(SocketEvent.PointsShopItemUpdated);
      socket.off(SocketEvent.PointsShopItemDeleted);
      socket.off(SocketEvent.PointsShopItemsRestocked);
    };
  }, [take, items, total]);

  const isDiscordLinked = !!profile?.discordId;

  const handleDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (active.id !== over?.id) {
        setItems((prev: ItemProps[]) => {
          const oldIndex = prev.findIndex((item: ItemProps) => item.id === active.id);
          const newIndex = prev.findIndex((item: ItemProps) => item.id === over!.id);
          const updatedItems = arrayMove(prev, oldIndex, newIndex);

          if (isAdminPage) {
            changeOrder(updatedItems, skip); // Pass the updated items to changeOrder function
          }

          return updatedItems;
        });
      }
    },
    [isAdminPage, changeOrder, skip]
  );

  const disableDND = !isAdminPage || sorter !== "order:asc";
  const persents = ((take / total) * 100).toFixed(2);

  return (
    <ContainerFlex>
      <TableHeader
        onOpenCreate={onOpenCreate}
        isAdminPage={isAdminPage}
        setValue={setValue}
        skip={skip}
        setSkip={setSkip}
        setSorter={setSorter}
        refetchTable={fetchData}
        accessFetch={accessFetch}
        onOpenRestock={onOpenRestock}
      />
      {isMobile && (
        <Carousel
          scrollToTarget={scrollToTarget}
          onSelectItem={onSelectItem}
          items={[items[0], items[1], items[2]]}
        ></Carousel>
      )}
      <ExtendedFlex style={{ width: "100%", margin: "24px 0 0 0" }}>
        {isLoading ? (
          <Flex align="center" width="100%" justify="center" padding="60px 0px">
            <DualRingLoader>
              <DualRingInner />
            </DualRingLoader>
          </Flex>
        ) : (
          (items?.length && (
            <CardsContainerFlex style={{ width: "100%" }}>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext disabled={disableDND} items={items} strategy={rectSortingStrategy}>
                  <ItemsContainer>
                    {items.map((item: ItemProps) => (
                      <>
                        <SortableItem
                          onSelectItem={onSelectItem}
                          onOpenDelete={onOpenDelete}
                          setSelectedItem={setSelectedItem}
                          item={item}
                          key={item.id}
                          id={item.id}
                          isAdminPage={isAdminPage}
                          disableDND={disableDND}
                          isFavorite={favoriteItems?.includes(item.id)}
                          fetchData={fetchData}
                          profile={profile}
                          onOpenLogin={onOpenLogin}
                        />
                      </>
                    ))}

                    {!isMobile && (
                      <Carousel
                        scrollToTarget={scrollToTarget}
                        onSelectItem={onSelectItem}
                        items={[items[0], items[1], items[2]]}
                      ></Carousel>
                    )}
                  </ItemsContainer>
                </SortableContext>
              </DndContext>

              <Flex width="100%" justify="center" align="center" margin="40px auto">
                <LoadMoreFooter>
                  {isLoading && (
                    <>
                      <CircularProgress isIndeterminate />
                    </>
                  )}

                  <>
                    <Progress
                      width={"100%"}
                      value={+persents}
                      borderRadius={10}
                      background={"rgba(171, 186, 219, 0.05)"}
                      height={2}
                      borderColor={"transparent"}
                    />
                    {!isLoading && <p>{`Showing ${items.length} out of ${total} items.`}</p>}

                    {total > take && (
                      <LoadMoreButton onClick={() => onLoadMore()}>Load More</LoadMoreButton>
                    )}
                  </>
                </LoadMoreFooter>
              </Flex>
            </CardsContainerFlex>
          )) || <NoEntitiesText>No Items</NoEntitiesText>
        )}
      </ExtendedFlex>
      <ChakraModal
        title={isDiscordLinked ? "BUYING AN ITEM" : "LINK YOUR DISCORD"}
        isOpen={isPurchaseOpen}
        onClose={onClosePurchase}
        content={
          isDiscordLinked ? (
            <PurchaseLayout onClose={onClosePurchase} selectedItem={selectedItem} />
          ) : (
            <DiscordLayout />
          )
        }
      />

      <ChakraModal
        isOpen={isCreateOpen || isEditOpen}
        onClose={onClose}
        styles={css`
          max-height: 700px;
        `}
        title={selectedItem ? "EDIT ITEM" : "NEW ITEM"}
        content={
          <AdminActionLayout
            fetchData={() => {
              refetchDashboard();
            }}
            selectedItem={selectedItem}
            onClose={onClose}
          />
        }
      />
      <ChakraModal
        isOpen={isDeleteOpen}
        onClose={onClose}
        content={
          <AdminDeleteModal
            fetchData={() => {
              refetchDashboard();
            }}
            selectedItemId={selectedItem?.id}
            onClose={onClose}
          />
        }
      />
      <ChakraModal
        isOpen={isRestockOpen}
        onClose={onClose}
        content={<AdminRestockModal onClose={onClose} />}
      />
    </ContainerFlex>
  );
};

const LoadMoreFooter = styled.div`
  width: 100%;
  max-width: 280px;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-style: normal;
  color: var(--clouds-100, #abbadb);
`;

const LoadMoreButton = styled.button`
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--dark-foggy-night-50, rgba(29, 34, 52, 0.5));
  background: var(--Dark-Midnight, #141722);
  color: var(--pure-sky-100, #00b2ff);
  transition: 0.2s all ease-in-out;

  &:hover {
    border-color: #141722;
    color: #141722;
    background: #00b2ff;
  }

  &:disabled {
    pointer-events: none;
  }
`;
export default withApp(ItemsTable);
