import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { IUserDto } from "interfaces";
import MainTab from "./components/TabContent/MainTab";
import PointsShopTab from "../ProfileModalTabs/PointsShopTab";
import MyBetsTab from "../ProfileModalTabs/MyBetsTab";
import ConnectionsTab from "./components/TabContent/ConnectionsTab";
import RafflesTab from "../ProfileModalTabs/RafflesTab";
import { useAppContext } from "contexts";
import { triggerNotification } from "components/TheComponents/Notification";
import { ALL_ADMIN_ROLES } from "helpers/constants";
import { useSearchParams } from "next/navigation";
import { ProfileTabs, ProfileTabsListRestricted } from "types/profile";
import { GenericState } from "types/games";
import { TransactionsProps } from ".";
import ReviewModalLayout from "components/points-shop/Modals/ReviewModalLayout";
import { useQuery } from "@tanstack/react-query";
import CoinBoxesHistoryTab from "../ProfileModalTabs/CoinBoxesHistoryTab";

type ProfileContextType = {
  activeTab: ProfileTabs;
  changeActiveTab: (value: ProfileTabs) => void;
  activeTabContent: React.JSX.Element;
  profile: IUserDto | null;
  updateUser: ({ field, value }: { field: string; value: string | number }) => Promise<void>;
  updateAddress: ({
    field,
    value,
  }: {
    field: string;
    value: string | number | null;
  }) => Promise<void>;
  isAdminCanEdit: boolean;
  canSeePointsShopTab: boolean;
  allowedNav: ProfileTabs[];
  isOwnProfile: boolean;
  fetchUser: () => void;
  isFetchingContextUser: boolean;
  setActiveUserId: (userId: string) => void;
  userId?: string;
  transaction: GenericState<TransactionsProps | undefined>;
};

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

type ProfileProviderProps = PropsWithChildren;

const ProfileProvider: React.FC<ProfileProviderProps> = ({ children }) => {
  const { refetchProfile, accessFetch, profile, apiService } = useAppContext();
  const [transactionState, setTransaction] = useState<TransactionsProps | undefined>();

  const [userId, setActiveUserId] = useState(profile?.id);
  const params = useSearchParams();

  const isOwnProfile = userId === profile?.id;

  const [activeTab, setActiveTab] = useState<ProfileTabs>(ProfileTabs.Main);
  const changeActiveTab = (value: ProfileTabs) => {
    setActiveTab(value);
  };

  useEffect(() => {
    const initTab = params.get("selectedTab");
    switch (initTab) {
      case "connections":
        setActiveTab(ProfileTabs.Connections);
        break;
    }
  }, [params]);

  const {
    data: contextUser,
    refetch: fetchUser,
    isFetching,
  } = useQuery({
    queryKey: ["fetch-context-user", userId],
    queryFn: async () => {
      if (isOwnProfile) {
        const { data } = await refetchProfile();
        if (data) {
          return data;
        }
      } else {
        if (userId) {
          try {
            const userDataResponse = await apiService.request(
              `/user/${userId}?withStats=true&withCrypto=true`
            );

            const userData = await userDataResponse?.json();

            return userData;
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      }
      return {};
    },
    initialData: null,
  });

  const activeTabContent = useMemo(() => {
    if (contextUser) {
      switch (activeTab) {
        case ProfileTabs.Bets:
          return <MyBetsTab userId={contextUser.id} />;
        case ProfileTabs.Connections:
          return <ConnectionsTab isCanEdit={isOwnProfile} />;
        case ProfileTabs.Raffles:
          return <RafflesTab user={contextUser} />;
        case ProfileTabs.Shop:
          return <PointsShopTab user={contextUser} />;
        case ProfileTabs.Transactions:
          return <ReviewModalLayout transactionsProps={transactionState} />;
        case ProfileTabs.Boxes:
          return <CoinBoxesHistoryTab />;
        case ProfileTabs.Main:
        default:
          return <MainTab user={contextUser} />;
      }
    }
    return <></>;
  }, [activeTab, isOwnProfile, transactionState, contextUser]);

  const updateAddress = useCallback(
    async ({ field, value }: { field: string; value: string | number | null }) => {
      try {
        const response = await accessFetch(`/user/${userId}/crypto-addresses`, {
          method: "PATCH",
          body: JSON.stringify({
            [field]: value,
          }),
        });
        if (response && (response.status === 200 || response.status === 201)) {
          triggerNotification({ type: "success", text: "User updated" });
          fetchUser();
        } else {
          triggerNotification({ type: "error", text: "Something went wrong" });
        }
      } catch (error) {
        triggerNotification({ type: "error", text: (error as any)?.message ?? "Error" });
        console.error("Error fetching data:", error);
      }
    },
    [accessFetch, userId, fetchUser]
  );

  const updateUser = useCallback(
    async ({ field, value }: { field: string; value: string | number }) => {
      try {
        const response = await accessFetch(`/user/${userId}/`, {
          method: "PATCH",
          body: JSON.stringify({
            [field]: value,
          }),
        });
        if (response && (response.status === 200 || response.status === 201)) {
          triggerNotification({ type: "success", text: "User updated" });
          fetchUser();
        } else {
          triggerNotification({ type: "error", text: "Something went wrong" });
        }
      } catch (error) {
        triggerNotification({ type: "error", text: (error as any)?.message ?? "Error" });
        console.error("Error fetching data:", error);
      }
    },
    [accessFetch, userId, fetchUser]
  );

  const canSeeRafflesTab = isOwnProfile
    ? true
    : !!profile?.roles.some(
        (role) =>
          role.name === ALL_ADMIN_ROLES.SUPER_ADMIN ||
          role.name === ALL_ADMIN_ROLES.RAFFLE_ADMIN ||
          role.name === ALL_ADMIN_ROLES.USERS_ADMIN
      );

  const canSeePointsShopTab = isOwnProfile
    ? true
    : !!profile?.roles.some(
        (role) =>
          role.name === ALL_ADMIN_ROLES.SUPER_ADMIN ||
          role.name === ALL_ADMIN_ROLES.POINTS_SHOP_ADMIN ||
          role.name === ALL_ADMIN_ROLES.USERS_ADMIN
      );

  const canSeeConnectionsTab = isOwnProfile
    ? true
    : !!profile?.roles.some(
        (role) =>
          role.name === ALL_ADMIN_ROLES.SUPER_ADMIN || role.name === ALL_ADMIN_ROLES.USERS_ADMIN
      );

  const isAdminCanEdit = !!profile?.roles.some(
    (role) => role.name === ALL_ADMIN_ROLES.SUPER_ADMIN || role.name === ALL_ADMIN_ROLES.USERS_ADMIN
  );
  const allowedNav = ProfileTabsListRestricted.filter((value) => {
    if (value === ProfileTabs.Shop && !canSeePointsShopTab) return false;
    if (value === ProfileTabs.Connections && !canSeeConnectionsTab) return false;
    if (value === ProfileTabs.Raffles && !canSeeRafflesTab) return false;
    if (value === ProfileTabs.Transactions && !transactionState) return false;
    return true;
  });

  const transaction = useMemo<GenericState<TransactionsProps | undefined>>(() => {
    return {
      value: transactionState,
      setValue: setTransaction,
    };
  }, [transactionState]);

  return (
    <ProfileContext.Provider
      value={{
        transaction,
        activeTab,
        changeActiveTab,
        activeTabContent,
        profile: contextUser,
        isFetchingContextUser: isFetching,
        updateUser,
        updateAddress,
        isAdminCanEdit,
        canSeePointsShopTab,
        allowedNav,
        isOwnProfile,
        fetchUser,
        setActiveUserId,
        userId,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

const useProfileContext = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("useProfileContext must be used within a ProfileProvider");
  }

  return context;
};

export { ProfileProvider, useProfileContext };
