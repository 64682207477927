const TOTAL_TICKETS_COUNT = 100_000_000;

export default function useCoinBoxRollSeed() {
  async function sha512(str: string) {
    return crypto.subtle.digest("SHA-512", new TextEncoder().encode(str)).then((buf) => {
      return Array.prototype.map
        .call(new Uint8Array(buf), (x) => ("00" + x.toString(16)).slice(-2))
        .join("");
    });
  }

  async function random(seeds: { serverSeed: string; clientSeed: string }) {
    const combinedHash = await sha512([seeds.serverSeed, seeds.clientSeed].join(":"));
    const hashInt = BigInt("0x" + combinedHash);
    const maxInt = BigInt(TOTAL_TICKETS_COUNT);
    return Number((hashInt % maxInt) + BigInt(1));
  }
  
  return {
    getResult: random,
  };
}
