import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
`;

export const Header = styled.h1`
  gap: 4px;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #e2f1ff;
  margin-bottom: 24px;

  @media (max-width: 600px) {
    margin-bottom: 12px;
  }
`;
