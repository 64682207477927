import { Flex } from "components/common";
import { useAppContext } from "contexts";
import { useCoinBoxes } from "hooks/treasury/useCoinBoxes";
import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";

const TreasuryBanner = ({ onClick }: { onClick: () => void }) => {
  const { isFeatureEnabled } = useAppContext();
  const { data } = useCoinBoxes();
  const counter = data?.reduce((acc, i) => (i.isReady ? acc + 1 : acc), 0) ?? 0;

  if (!isFeatureEnabled("ALLOW_TREASURY")) {
    return <></>;
  }
  return (
    <Link href={"/treasury"} onClick={onClick}>
      <TreasuryButton>
        {!!counter && <Label>{counter}</Label>}
        <Flex align="center" gap={4}>
          <Image src={"/images/Treasury/treasury-mobile.png"} alt="" width={60} height={60} />
          <Image src={"/images/Treasury/treasury-desktop.png"} alt="" width={100} height={40} />
        </Flex>
      </TreasuryButton>
    </Link>
  );
};
const TreasuryButton = styled.button`
  position: relative;
  margin-top: 1rem;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #4099fb;
  padding: 8px;
  background: radial-gradient(
      91.29% 91.29% at 0% 100%,
      rgba(1, 170, 255, 0.85) 0%,
      rgba(0, 80, 199, 0) 100%
    ),
    #161c2a;
`;

const Label = styled.div`
  padding: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background: var(--color-error);
  border: 2px solid #141722;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-style: italic;
  font-family: var(--font-family-exo);
  font-size: 12px;
  line-height: normal;
`;

export default TreasuryBanner;
