import React from "react";
import { Button, ButtonBlue, Flex } from "components/common";
import styled, { css } from "styled-components";
import Image from "next/image";

interface FailedPurchaseLayoutProps {
  onClose: () => void;
  errorMessage: string;
}

const FailedPurchaseLayout: React.FC<FailedPurchaseLayoutProps> = ({
  onClose,
  errorMessage,
}): React.ReactElement => {
  const ButtonFlex = styled(Flex)`
    margin: auto;
    @media (min-width: 600px) {
      width: 50%;
    }
  `;

  return (
    <Flex height="100%" justify="center" column>
      <Flex width="100%" align="center" column>
        <Image alt="guy" width={128} height={122} src="/images/PointShopComponent/sad-pepe.png" />
        <ErrorMessage>{errorMessage}</ErrorMessage>

        <ButtonFlex>
          <Button
            styles={css`
              margin: auto;
            `}
            onClick={onClose}
          >
            <ButtonBlue>Close</ButtonBlue>
          </Button>
        </ButtonFlex>
      </Flex>
    </Flex>
  );
};

const ErrorMessage = styled(Flex)`
  color: rgb(255, 51, 85);
  margin: 15px 0;
  font-family: var(--font-family-golos);
`;

export default FailedPurchaseLayout;
