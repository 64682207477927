import { useAppContext } from "contexts";
import { IDtoLeaderboardUser, LeaderboardRaffleWinner, TheLeaderboardSourceKeys } from "interfaces";
import { useQuery } from "@tanstack/react-query";

interface useLeaderboardUsersParams {
  leaderboardId?: string;
  take?: number;
  skip?: number;
  enabled?: boolean;
  hideUserNames?: boolean;
}
export default function useLeaderboardRaffleWinnerPrivate({
  leaderboardId,
  take = 10,
  skip = 0,
  enabled = true,
}: useLeaderboardUsersParams) {
  const { apiService } = useAppContext();

  return useQuery<{ items: IDtoLeaderboardUser[]; total: number }>({
    queryKey: ["leaderboard-raffle-winners-private", { leaderboardId, take, skip }],
    queryFn: async () => {
      const usersResponse = await apiService.request(
        `/leaderboard/${leaderboardId}/raffle-winners/list/private?skip=${skip}&take=${take}`
      );

      const usersData = await usersResponse?.json();
      const preparedRaffleData = usersData.items?.map(
        ({ leaderboardUser, ...el }: LeaderboardRaffleWinner) => ({
          ...el,
          ...leaderboardUser,
          position: el?.position,
        })
      );

      return usersData?.items
        ? { items: preparedRaffleData, total: usersData.totalCount }
        : { items: [], total: 0 };
    },
    retry: false,
    enabled: !!leaderboardId && enabled,
    placeholderData: (previousData) => previousData,
    initialData: { items: [], total: 0 },
  });
}
