import { socket } from "socket";
import { URL_BE } from "types/constants";

export class ApiError implements Error {
  constructor(name: string, message: string, code: number) {
    this.name = name;
    this.message = message;
    this.code = code;
  }
  code: number;
  name: string;
  message: string;
  stack?: string | undefined;
  cause?: unknown;
}
export default class RefreshService {
  private urlRefresh = new URL("/auth/refresh", URL_BE);
  /**
   * Stores state of refresh request to perform only single one in case we have multi requests with 401.
   */
  private refreshPromise: Promise<Response> | null = null;
  private canRefresh: boolean = true;
  /**
   * Tracks socket reconnection process - in order to avoid multiple simultaneous requests
   */
  private isReconnectingSocket: boolean = false;
  /**
   * In order to execute reconnect to events when new socket connection established
   */
  private _socketConnectionKey = 0;
  get socketConnectionKey() {
    return this._socketConnectionKey;
  }

  /**
   * Callback to execute after refresh returned 401.
   */
  protected clearUserProfile?: () => void;

  protected async handleUnauthorizedResponse(onRefreshSuccess: () => Promise<any>): Promise<any> {
    const refreshResponse = await this.tryRefreshAsync();
    if (refreshResponse && refreshResponse.status != 401) {
      this.canRefresh = true;
      return await onRefreshSuccess();
    } else {
      this.canRefresh = false;
      this.clearUserProfile?.();
      throw new ApiError("Unauthorized", "User has to be authenticated to perform action", 401);
    }
  }

  protected async tryRefreshAsync(): Promise<any> {
    if (this.refreshPromise === null && this.canRefresh) {
      console.log("Refreshing token");
      const url = new URL(this.urlRefresh.pathname, URL_BE);
      const fetchOptions: RequestInit = {
        method: "GET",
        credentials: "include",
        headers: {
          ...(url.href.startsWith(URL_BE) && {
            "Content-Type": "application/json",
          }),
        },
      };

      this.refreshPromise = fetch(this.urlRefresh.href, fetchOptions).then((response) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            this.refreshPromise = null;
            this.reconnectSocket();
            return resolve(response);
          }, 1000);
        });
      });
    }
    return this.refreshPromise;
  }

  setCanRefresh(value: boolean) {
    this.canRefresh = value;
  }

  getCanRefresh() {
    return this.canRefresh;
  }

  reconnectSocket(): void {
    console.log("try to reconnect socket");
    if (this.isReconnectingSocket === false) {
      this.isReconnectingSocket = true;
      socket.disconnect();
      socket.connect();
    }
  }

  init(clearUserProfileCallback: () => void) {
    this.clearUserProfile = clearUserProfileCallback;
    socket.on("connect", () => {
      console.log("reconnected socket");
      this.isReconnectingSocket = false;
      this._socketConnectionKey++;
    });

    socket.on("disconnect", () => {
      console.log("disconnected socket");
      this._socketConnectionKey++;
    });
  }
}
