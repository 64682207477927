import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { Flex } from "components/common";
import styled, { css } from "styled-components";
import { OptionalChakraModalProps } from "types/chakra-modal";

interface ChakraModalProps extends OptionalChakraModalProps {
  content: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const hideScrollbarCss = `/* Hide scrollbars on all browsers */
/* For Webkit-based browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  display: none;
}

/* For non-Webkit browsers */
body {
  scrollbar-width: none;
}`;

const modalHeaderStyles = css`
  color: #d2eaff;
  font-family: var(--font-family-golos);
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
`.toString();

const ChakraModal: React.FC<ChakraModalProps> = ({
  content,
  footer,
  title,
  titleSlot,
  size = "lg",
  styles,
  showClose = true,
  actions,
  isOpen,
  background = "transparent",
  animation,
  onClose,
  bodyStyle,
  isOverlay = true,
  ...props
}) => {
  const dynamicStyles = `
  ${hideScrollbarCss}
  ${styles}
  ${showClose ? "padding-top: 0px;" : ""}
`;
  return (
    <>
      <Modal
        autoFocus={false}
        size={size}
        closeOnOverlayClick={false}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        {isOverlay ? <ModalOverlay /> : <></>}
        <UIModalContent
          animation={animation}
          css={dynamicStyles}
          bg="primary"
          overflowY="scroll"
          maxWidth={props.maxWidth}
        >
          {showClose ? (
            <Flex
              style={{
                position: "sticky",
                top: 0,
                zIndex: 99,
                background,
                width: "100%",
                paddingBottom: 5,
              }}
              justify="flex-end"
              height="30px"
              align="center"
            >
              {typeof actions !== "undefined" ? actions : null}
              <Close onClick={onClose} />
            </Flex>
          ) : null}
          {typeof title !== "undefined" ? (
            <ModalHeader css={modalHeaderStyles}>{title}</ModalHeader>
          ) : typeof titleSlot !== "undefined" ? (
            <ModalHeader style={{ paddingBottom: "0px" }} css={modalHeaderStyles}>
              {titleSlot}
            </ModalHeader>
          ) : null}
          <ModalBody
            padding="20px 24px"
            css={`
              ${bodyStyle}
            `}
          >
            {content}
          </ModalBody>
          {typeof footer !== "undefined" ? (
            <ModalFooter
              css={`
                justify-content: center;
              `}
            >
              {footer}
            </ModalFooter>
          ) : null}
        </UIModalContent>
      </Modal>
    </>
  );
};

export default ChakraModal;

const Close = styled.div`
  --size: 14px;
  height: 30px !important;
  box-sizing: border-box;
  // position: sticky;
  top: 0px;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  background-image: url("/images/TheModalBox/close.svg");
  background-size: var(--size);
  background-repeat: no-repeat;
  background-position: center;
  background-clip: border-box;
  cursor: pointer;
  margin-right: 12px;
  margin-top: 12px;
`;

const UIModalContent = styled(ModalContent)`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
