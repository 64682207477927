import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import Dollar from "/public/images/icons/dollar-shape.svg";
import TicketG from "/public/images/icons/ticket.svg";
import GCoin from "/public/images/coins/g-coin.svg";

type CurrencyNumbersProps = {
  icon?: keyof typeof icons;
  className?: string;
};

const icons = {
  USD: <Dollar style={{ color: "#5EBD3D" }} />,
  GCoin: <GCoin />,
  Raffle: <TicketG />,
};

function CurrencyNumbers({
  children,
  icon = "USD",
  className,
}: PropsWithChildren & CurrencyNumbersProps) {
  return (
    <CurrencyWrapper className={className}>
      {icon && <span className="currency-icon">{icons[icon]}</span>}
      {children}
    </CurrencyWrapper>
  );
}

const CurrencyWrapper = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: inherit;

  .currency-icon {
    /* width: 2em;
    height: 2em; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default CurrencyNumbers;
