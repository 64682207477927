import React, { PropsWithChildren } from "react";
import MFAModal from "components/ScreenComponent/MFAModals/MFAModal";
import QRСodeModal from "components/ScreenComponent/MFAModals/QRСodeModal";
import { useAppContext } from "contexts";

export interface IVerifierContext {
  verify: (callback: ({ otp }: { otp: string }) => void) => void;
  closeMfe: () => void;
}

export const VerifierContext = React.createContext<IVerifierContext>({
  verify: () => console.log("callback not provided"),
  closeMfe: () => {},
});

const VerifierProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { profile } = useAppContext();
  const verifyRef = React.useRef<{ callback: ({ otp }: { otp: string }) => void }>({
    callback: () => console.log("callback not provided"),
  });
  const [showVerifyModal, setVerifyModal] = React.useState(false);
  const [showQrModal, setShowQrModal] = React.useState(false);

  const verify = React.useCallback(
    (callbackToVerify: ({ otp }: { otp: string }) => void) => {
      verifyRef.current.callback = callbackToVerify;
      if (profile?.mfaSecret) {
        setVerifyModal(true);
      } else {
        setShowQrModal(true);
      }
    },
    [profile]
  );

  const onMfaClose = () => {
    verifyRef.current.callback = () => console.log("callback not provided");
    setVerifyModal(false);
  };

  const onQRModalClose = () => {
    setShowQrModal(false);
  };

  return (
    <VerifierContext.Provider value={{ verify, closeMfe: onMfaClose }}>
      <>
        {!!profile?.mfaSecret && !!showVerifyModal ? (
          <MFAModal
            onVerify={({ otp }) => verifyRef.current.callback({ otp })}
            onClose={onMfaClose}
          />
        ) : null}
        {showQrModal && <QRСodeModal onClose={onQRModalClose} />}
      </>
      <>{children}</>
    </VerifierContext.Provider>
  );
};

export function useVerifierContext() {
  const context = React.useContext(VerifierContext);
  if (context === undefined) {
    throw new Error("useVerifierContext must be used within a VerifierProvider");
  }
  return context;
}

export default VerifierProvider;
