import WrewardsButton from "components/wrewards-ui-kit/Button";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import Play from "assets/notifications/play.svg";
import { NotificationCardProps } from "types/notification";
import { useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import { useShowPageLayoutContext } from "contexts/ShowPageLayoutContext";
import Image from "next/image";

const NewStreamerNotificationCard = (props: NotificationCardProps) => {
  const router = useRouter();
  const { setShowChatSidebar } = useShowPageLayoutContext();

  const { title, info, onActionButtonClick, containerStyles } = props;

  const titleParts = useMemo(() => {
    const parts = title?.split(" ");
    let first = parts ? parts.slice(0, 3).join(" ") : "";
    let second = parts ? parts[parts.length - 1] : "";

    return [first, second];
  }, [title]);

  const onActionButtonClickInner = useCallback(() => {
    if (info.streamer && info.onActionClickLink) {
      onActionButtonClick && onActionButtonClick();
      router.push(info.onActionClickLink);
      setShowChatSidebar(false);
    }
  }, [info.onActionClickLink, info.streamer, onActionButtonClick, router, setShowChatSidebar]);

  return (
    <NotificationCardContainer styles={containerStyles}>
      <NotificationCardHeader>
        <Image width={48} height={48} alt="streamer-img" src={`/images/icons/new-streamer.svg`} />
        <NotificationTitleContainer>
          <span>{titleParts[0]}</span>
          <span>{titleParts[1]}</span>
        </NotificationTitleContainer>
      </NotificationCardHeader>
      <NotificationCardContent>
        <Image
          width={56}
          height={56}
          alt="streamer-img"
          src={`/images/FeaturedCreatorsComponent/${info.streamer || "samjuniortv"}.svg`}
        />
        <StreamerName>{info.streamer || "samjuniortv"}</StreamerName>
      </NotificationCardContent>
      <WrewardsButton
        onClick={onActionButtonClickInner}
        variant="game-action-blue"
        style={{ fontSize: "12px", width: "100%", padding: "8px" }}
      >
        {Play()}
        {info.actionButtonText}
      </WrewardsButton>
    </NotificationCardContainer>
  );
};
export default NewStreamerNotificationCard;

const StreamerName = styled.div`
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-weight: 600;
  color: var(--daylight-color);
`;

const NotificationStreamerAvatarContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #00b2ff;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  z-index: 2;
  overflow: hidden;
  & > img {
    max-width: none;
  }
`;

const NotificationCardContent = styled.div`
  width: 100%;
  display: flex;
  min-height: 75px;
  padding: 16px 0 8px 0;
  position: relative;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  flex-grow: 1;
`;

const NotificationCardContainer = styled.div<{ styles?: FlattenSimpleInterpolation }>`
  background: radial-gradient(
    55.8% 99.57% at 25% 100%,
    rgba(146, 201, 126, 0.45) 0%,
    rgba(66, 145, 92, 0.3375) 55%,
    rgba(75, 125, 57, 0) 155%
  );

  gap: 16px;
  width: 100%;
  min-width: 230px;
  max-height: 230px;
  max-width: 230px;
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;

  @media (max-width: 500px) {
    max-width: 100%;
  }
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  ${({ styles }) =>
    styles &&
    css`
      ${styles}
    `}
`;

const NotificationCardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  & > svg {
    min-height: 48px;
    min-width: 48px;
  }
`;

const NotificationTitleContainer = styled.div`
  font-family: var(--font-family-exo);
  font-size: 16px;
  font-style: italic;
  font-weight: 800;
  line-height: 20.8px;
  text-align: left;
  text-transform: uppercase;
  & > :nth-child(1) {
    margin-right: 5px;
    color: var(--daylight-color);
  }
  & > :nth-child(2) {
    color: var(--toxic-color-dark);
  }
`;
