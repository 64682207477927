import { Box, Collapse, Fade, useDisclosure } from "@chakra-ui/react";
import { Flex } from "components/common";
import { Header } from "components/provably-fair/ProvablyFairComponent/styles";
import ChakraModal from "components/TheComponents/Modal";
import WrewardsButton from "components/wrewards-ui-kit/Button";
import CopyButton from "components/wrewards-ui-kit/Button/CopyButton";
import { InputContainer, InputWrapper } from "components/wrewards-ui-kit/Input/input-elements";
import useCoinBoxRollSeed from "hooks/seed/useCoinBoxRollSeed";
import { useMemo, useState } from "react";
import styled from "styled-components";

const CoinBoxProvably: React.FC<{ serverSeed: string; clientSeed: string }> = ({
  serverSeed,
  clientSeed,
}) => {
  const [isOpen, setOpen] = useState(false);
  const { getResult } = useCoinBoxRollSeed();
  const [result, setResult] = useState<number | null>(null);
  const { isOpen: isCodeOpen, onToggle } = useDisclosure();

  const ProvablyFairContent = useMemo(() => {
    return (
      <Flex column>
        <Header>Seed Pair</Header>

        <Flex gap={24} column justify="stretch" width="100%">
          <InputContainer>
            <Label>Server Seed </Label>
            <InputWrapper>
              <input type="text" value={serverSeed} readOnly />
              <CopyButton copyValue={serverSeed} />
            </InputWrapper>
          </InputContainer>

          <InputContainer>
            <Label>Client Seed</Label>
            <InputWrapper>
              <input type="text" value={clientSeed} style={{ textOverflow: "ellipsis" }} readOnly />
              <CopyButton copyValue={clientSeed ?? ""} />
            </InputWrapper>
          </InputContainer>
          <WrewardsButton style={{ width: "100%" }} onClick={onToggle}>
            Show verification code
          </WrewardsButton>
          <Collapse in={isCodeOpen} animateOpacity>
            <CodeBlock>
              {`
          function sha512(str: string) {
            return crypto.subtle.digest('SHA-512', new TextEncoder().encode(str)).then((buf) => {
              return Array.prototype.map.call(new Uint8Array(buf), (x) => ('00' + x.toString(16)).slice(-2)).join('');
            });
          }

            function random(seeds: { serverSeed: string; clientSeed: string }) {
              const combinedHash = await sha512([seeds.serverSeed, seeds.clientSeed].join(':'));
              const hashInt = BigInt('0x' + combinedHash);
              const maxInt = BigInt(TOTAL_TICKETS_COUNT);
              return Number((hashInt % maxInt) + BigInt(1));
            }
              `}
            </CodeBlock>
          </Collapse>

          {result && (
            <InputContainer>
              <Label>Random result</Label>
              <InputWrapper>
                <input type="text" value={result} style={{ textOverflow: "ellipsis" }} readOnly />
              </InputWrapper>
            </InputContainer>
          )}

          <WrewardsButton
            style={{ width: "100%" }}
            onClick={async () => {
              const res = await getResult({ serverSeed, clientSeed });
              setResult(res);
            }}
          >
            Verify
          </WrewardsButton>
          {/* <InputContainer>
            <Label>Nonce</Label>
            <InputWrapper>
              <input
                type="text"
                value={nonce}
                readOnly
                style={{ textOverflow: "ellipsis" }}
              />
              <CopyButton copyValue={nonce} />
            </InputWrapper>
          </InputContainer> */}
        </Flex>
      </Flex>
    );
  }, [serverSeed, clientSeed, result, getResult]);

  return (
    <>
      <ChakraModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        content={ProvablyFairContent}
        // {...modalProps}
      />
      <WrewardsButton
        variant="game-action-gray"
        style={{ padding: "8px 12px", width: "100%" }}
        onClick={() => setOpen(true)}
      >
        Verify
      </WrewardsButton>
    </>
  );
};

const CodeBlock = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-x: auto;
  max-width: 100%;
  font-size: 0.875rem;
  background-color: #21252e;
  border-radius: 4px;
  padding: 16px;
`;

const Label = styled.div`
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  text-transform: capitalize;
  color: var(--daylight-daylight-main, #d5e0f5);
`;

export default CoinBoxProvably;
