import { ALL_ADMIN_ROLES } from "helpers/constants";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import styled, { css } from "styled-components";

export interface ISideMenuItem {
  isActive?: boolean;
  href: string;
  match?: Array<string | RegExp>;
  name: string;
  section: string;
  permissions: string[];
}

export interface ISideMenuSection {
  isOpen: boolean;
  items: ISideMenuItem[];
  name: string;
}

const sideMenuItems: ISideMenuItem[] = [
  {
    href: "/admin/users",
    name: "Users",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.USERS_ADMIN],
  },
  {
    href: "/admin/black-list",
    name: "BlackList",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.USERS_ADMIN],
  },
  {
    href: "/admin/users-multi",
    name: "MultiAccounts",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.USERS_ADMIN],
  },
  {
    href: "/admin/raffles",
    name: "Raffles",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.RAFFLE_ADMIN],
  },
  {
    href: "/admin",
    match: ["/admin", /^[\/]admin[\/]leaderboard(?:[\/].*)?/],
    name: "Leaderboard",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.LEADERBOARD_ADMIN],
  },
  {
    href: "/admin/pachinko",
    name: "Pachinko",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.PACHINKO_ADMIN],
  },
  {
    href: "/admin/stream-guests",
    name: "Stream guests",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.PICK_A_GUEST_ADMIN],
  },
  {
    href: "/admin/site-rewards",
    name: "Site Rewards",
    section: "Main",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.SITE_REWARD_ADMIN],
  },
  ...(process.env.NEXT_PUBLIC_SHOW_CONTACT_US === "true"
    ? [
        {
          href: "/admin/contact-requests",
          name: "Contact Requests",
          section: "Main",
          permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.USERS_ADMIN],
        },
      ]
    : []),
  ...(process.env.NEXT_PUBLIC_SHOW_ADMIN_CALENDAR === "true"
    ? [
        {
          href: "/admin/calendar",
          name: "Calendar",
          section: "Main",
          permissions: [ALL_ADMIN_ROLES.CALENDAR_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
        },
      ]
    : []),
  {
    href: "/admin/points-shop",
    name: "Point shop",
    section: "Points shop",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.POINTS_SHOP_ADMIN],
  },
  {
    href: "/admin/points-shop/transactions",
    name: "Transactions",
    section: "Points shop",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.POINTS_SHOP_ADMIN],
  },
  {
    href: "/admin/permissions",
    name: "Admin List",
    section: "Roles management",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/permissions/roles",
    name: "Roles Overview",
    section: "Roles management",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/gallery",
    name: "Gallery",
    section: "Main",
    permissions: [
      ALL_ADMIN_ROLES.RAFFLE_ADMIN,
      ALL_ADMIN_ROLES.SUPER_ADMIN,
      ALL_ADMIN_ROLES.SITE_REWARD_ADMIN,
      ALL_ADMIN_ROLES.CASE_ADMIN,
    ],
  },
  {
    href: "/admin/lootbox",
    name: "Loot boxes",
    section: "Loot boxes",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.CASE_ADMIN],
  },
  {
    href: "/admin/lootbox-item",
    name: "Loot boxes items",
    section: "Loot boxes",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.CASE_ADMIN],
  },
  {
    href: "/admin/lootbox-transaction",
    name: "Loot boxes transactions",
    section: "Loot boxes",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.CASE_ADMIN],
  },
  {
    href: "/admin/slot",
    name: "Slots",
    section: "Slot reviews",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/slot-provider",
    name: "Slot providers",
    section: "Slot reviews",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/games/mines",
    name: "Mines",
    section: "Games",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/games/coinflip",
    name: "Coinflip",
    section: "Games",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/games/roulette",
    name: "Roulette",
    section: "Games",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/games/rates",
    name: "Rates",
    section: "Games",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
  {
    href: "/admin/treasury",
    name: "Boxes",
    section: "Treasure",
    permissions: [ALL_ADMIN_ROLES.SUPER_ADMIN],
  },
];

const isActive = (pathname: string, match: string | RegExp | Array<string | RegExp>): boolean => {
  let result = false;
  if (match === "#") {
    result = false;
  } else if (match instanceof RegExp) {
    result = match.test(pathname);
  } else if (Array.isArray(match)) {
    result = match.some((match) => isActive(pathname, match));
  } else if (typeof match === "string") {
    result = pathname === match;
  }
  return result;
};

export const SideMenuLayout = ({ roles }: { roles: string[] }): React.ReactElement => {
  const router = useRouter();
  const [isOpen, setIsOpen] = React.useState<string[]>([]);

  const items: ISideMenuItem[] = React.useMemo(() => {
    return sideMenuItems
      .filter((item) => item.permissions.some((permission) => roles?.includes(permission)))
      .map(({ href, match = [], ...item }) => {
        return {
          isActive: isActive(router.pathname, [href, ...match]),
          href,
          ...item,
        };
      });
  }, [router.pathname, roles]);

  React.useEffect(() => {
    const activeMenusJSON = localStorage.getItem("activeMenus");
    const activeMenus = activeMenusJSON ? JSON.parse(activeMenusJSON) : [];
    const activeItemSection = items.find((item) => item.isActive)?.section;

    const mergedMenus = Array.from(new Set([...activeMenus, activeItemSection]));

    setIsOpen(mergedMenus);
  }, [items]);

  const sections: ISideMenuSection[] = React.useMemo(() => {
    const sections = Array.from(new Set(items.map((item) => item.section)));

    return sections.map((section) => {
      return {
        isOpen: isOpen.includes(section),
        items: items.filter((item) => item.section === section),
        name: section,
      };
    });
  }, [items, isOpen]);

  const onClick = (name: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    setIsOpen((isOpen: string[]) => {
      if (isOpen.includes(name)) {
        const updatedIsOpen = isOpen.filter((_) => _ !== name);
        localStorage.setItem("activeMenus", JSON.stringify(updatedIsOpen));
        return updatedIsOpen;
      } else {
        const updatedIsOpen = [...isOpen, name];
        localStorage.setItem("activeMenus", JSON.stringify(updatedIsOpen));
        return updatedIsOpen;
      }
    });
  };

  return (
    <Box>
      {sections.map((section, sindex) => {
        return (
          <div key={`section-${sindex}`}>
            <Section {...section}>
              <Title isSection={true}>{section.name}</Title>
              <SectionV {...section} onClick={onClick(section.name)} />
            </Section>
            {section.isOpen ? (
              <List key={`list-${sindex}`}>
                {section.items.map((item, index) => {
                  return (
                    <Item key={`item-${sindex}-${index}`} {...item}>
                      <Title>{item.name}</Title>
                    </Item>
                  );
                })}
              </List>
            ) : null}
          </div>
        );
      })}
    </Box>
  );
};

const Box = styled.div`
  box-sizing: border-box;
  height: 100%;
  background: #0b101b;
`;

const Section = styled.div<ISideMenuSection>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  background: rgba(35, 163, 255, 0.05);
`;

const SectionV = styled.div<ISideMenuSection>`
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  right: 16px;
  width: 24px;
  height: 24px;
  background: rgba(35, 163, 255, 0.05);
  border-radius: 8px;
  cursor: pointer;
  &::before {
    content: ">";
    ${({ isOpen }) =>
      isOpen
        ? css`
            transform: rotate(-90deg);
          `
        : css`
            transform: rotate(90deg);
          `}
  }
`;

const List = styled.div`
  box-sizing: border-box;
  padding: 16px 8px;
`;

const Item = styled(Link)<ISideMenuItem>`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  color: rgba(210, 234, 255, 0.75);

  ${({ isActive }) =>
    isActive
      ? css`
          background: rgba(35, 163, 255, 0.05);
          border-radius: 8px;
          color: #3b8be9;
        `
      : null}
`;

const Title = styled.div<{ isSection?: boolean }>`
  box-sizing: border-box;
  color: inherit;
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 19px;
  text-transform: uppercase;
  ${({ isSection }) =>
    isSection
      ? css`
          color: #d2eaff;
        `
      : null}
`;
